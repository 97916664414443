<template>
    <div>
        <v-row no-gutters class="mb-0 pb-0 mt-3 mb-2">
            <p style="font-size:16px; color:#000000">
                General params
            </p>
        </v-row>
        <v-row no-gutters class="mt-n1 mb-5">
            <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.materialPrice"
                    :rules="[rules.required, rules.number]"
                    type="number"
                    class="py-0 my-0"
                    prepend-icon="mdi-package-variant-closed"
                    label="Material price (usd/lb)"
                    required
                />
            </v-col>
            <v-col
                cols="12"
                xl="6"
                lg="6"
                md="6"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.paintPrice"
                    :rules="[rules.required, rules.number]"
                    type="number"
                    class="py-0 my-0"
                    prepend-icon="mdi-format-color-fill"
                    label="Paint price (usd/lb)"
                    required
                />
            </v-col>
        </v-row>
        <v-row no-gutters class="mt-1">
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.waste"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    class="py-0 my-0"
                    prepend-icon="mdi-grain"
                    label="Waste (0-100%)"
                    required
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.exchange"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    class="py-0 my-0"
                    prepend-icon="mdi-cash"
                    label="Exchange"
                    required
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.margin"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    class="py-0 my-0"
                    prepend-icon="mdi-percent"
                    label="Margin"
                    required
                />
            </v-col>
        </v-row>
        <v-row no-gutters class="mb-0 pb-0 mt-3 mb-2">
            <p style="font-size:16px; color:#000000">
                Area sizes (sqft)
            </p>
        </v-row>
        <v-row v-if="calculation.areas" no-gutters class="mt-n1">
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.areas.small"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-ruler"
                    label="Small"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.areas.medium"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="Medium"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.areas.large"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="Large"
                    required
                    class="py-0 my-0"
                />
            </v-col>
        </v-row>
        <v-row no-gutters class="mb-0 pb-0 mt-3 mb-2">
            <p style="font-size:16px; color:#000000">
                Weight per Area (lbs/sqft)
            </p>
        </v-row>
        <v-row v-if="calculation.weights" no-gutters class="mt-n1">
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.weights.small"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-weight-pound"
                    label="Small"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.weights.medium"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="Medium"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.weights.large"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="Large"
                    required
                    class="py-0 my-0"
                />
            </v-col>
        </v-row>
        <v-row no-gutters class="mb-0 pb-0 mt-3 mb-2">
            <p style="font-size:16px; color:#000000">
                Material costs per quantity
            </p>
        </v-row>
        <v-row v-if="calculation.materials" no-gutters class="mt-n1">
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.materials.small"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-package-variant-closed-plus"
                    label="> 1"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.materials.medium"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="> 10,000"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.materials.large"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="> 20,000"
                    required
                    class="py-0 my-0"
                />
            </v-col>
        </v-row>
        <v-row no-gutters class="mb-0 pb-0 mt-3 mb-2">
            <p style="font-size:16px; color:#000000">
                Assembly costs per quantity
            </p>
        </v-row>
        <v-row v-if="calculation.assambly" no-gutters class="mt-n1">
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.assambly.small"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-package-variant-closed-plus"
                    label="> 1"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.assambly.medium"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="> 10,000"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.assambly.large"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="> 20,000"
                    required
                    class="py-0 my-0"
                />
            </v-col>
        </v-row>
        <v-row no-gutters class="mb-0 pb-0 mt-3 mb-2">
            <p style="font-size:16px; color:#000000">
                Package costs per quantity
            </p>
        </v-row>
        <v-row v-if="calculation.packages" no-gutters class="mt-n1">
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.packages.small"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-package-variant-closed-plus"
                    label="> 1"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.packages.medium"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="> 10,000"
                    required
                    class="py-0 my-0"
                />
            </v-col>
            <v-col
                cols="12"
                xl="4"
                lg="4"
                md="4"
                sm="12"
                class="d-flex align-center"
            >
                <v-text-field
                    v-model="calculation.packages.large"
                    type="number"
                    :rules="[rules.required, rules.number]"
                    prepend-icon="mdi-arrow-split-vertical"
                    label="> 20,000"
                    required
                    class="py-0 my-0"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState, /*mapActions, mapMutations,*/ mapGetters } from 'vuex'

export default {
    name: 'CalculationParams',
    components: {
        // SendQuote,
    },
    props: {
        id: String,
        calculation: {
            type: Object,
            default() {
                return {}
            },
        },
        settings: Array,
    },
    data() {
        return {
            edited: false,
            weight: null,
            material: null,
            paint: null,
            loses: null,
            exchange: null,
            min: 0,
            max: 100,
            rules: {
                required: v => !!v || 'Required',
                number: v => v > 0 || 'The number must be greater than zero',
            },
        }
    },
    watch: {
        'calculation.margin': async function() {
            this.emitChanges()
        },
        'calculation.exchange': async function() {
            this.emitChanges()
        },
        'calculation.waste': async function() {
            this.emitChanges()
        },
        'calculation.paintPrice': async function() {
            this.emitChanges()
        },
        'calculation.materialPrice': async function() {
            this.emitChanges()
        },
        'calculation.areas.small': async function() {
            this.emitChanges()
        },
        'calculation.areas.medium': async function() {
            this.emitChanges()
        },
        'calculation.areas.large': async function() {
            this.emitChanges()
        },
        'calculation.weights.small': async function() {
            this.emitChanges()
        },
        'calculation.weights.medium': async function() {
            this.emitChanges()
        },
        'calculation.weights.large': async function() {
            this.emitChanges()
        },
        'calculation.materials.small': async function() {
            this.emitChanges()
        },
        'calculation.materials.medium': async function() {
            this.emitChanges()
        },
        'calculation.materials.large': async function() {
            this.emitChanges()
        },
        'calculation.assambly.small': async function() {
            this.emitChanges()
        },
        'calculation.assambly.medium': async function() {
            this.emitChanges()
        },
        'calculation.assambly.large': async function() {
            this.emitChanges()
        },
        'calculation.packages.small': async function() {
            this.emitChanges()
        },
        'calculation.packages.medium': async function() {
            this.emitChanges()
        },
        'calculation.packages.large': async function() {
            this.emitChanges()
        },
    },
    computed: {
        ...mapState(['saveBtn']),
        ...mapGetters(['getAlternatives']),
    },
    mounted() {},
    async created() {
        try {
            //console.log(this.calculation)
        } catch (error) {
            this.error = true
            this.errorMsg = error.message
        } finally {
            this.loading = false
        }
    },
    methods: {
        emitChanges() {
            this.$root.$emit('replaceCalculation', this.calculation)
        },
    },
}
</script>
